@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Light.woff2") format("woff2"),
    url("../fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
}
html,
body {
  width: 100%;
}
a {
  text-decoration: none;
  color: #f26432;
  transition: all 0.6s ease-out;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -ms-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
}
a:hover {
  text-decoration: none !important;
  cursor: pointer;
  color: #000;
}
a:focus {
  text-decoration: none;
  transition: all 1s ease;
}
:focus {
  outline: none !important;
}
::-moz-selection {
  background-color: #000;
  color: #fff;
}
::selection {
  background-color: #000;
  color: #fff;
}
.other-services-bottum-text .section-title::selection,
.services-tabs ul.nav.nav-tabs li a.active .services-text h2::selection,
.hero-title::selection {
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  color: #717b89;
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
}
p a {
  color: #f26432;
}
p strong {
  color: #176680;
  font-weight: 600;
}
p a:hover {
  color: #3f5d8c;
}
ul,
ol,
ul li,
ol li {
  margin: 0;
  padding: 0;
}
ul li a,
ol li a {
  color: #f26432;
}
ul li a:hover,
ol li a:hover {
  color: #000;
}
figure,
address {
  margin: 0;
}
input,
textarea,
select,
button {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  color: #000000;
  margin-bottom: 15px;
  text-transform: none;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #f26432;
}
h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
  color: #f26432;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: #000;
}
h1 {
  font-size: 40px;
  line-height: normal;
}
h2 {
  font-size: 32px;
  line-height: normal;
}
h3 {
  font-size: 28px;
  line-height: normal;
}
h4 {
  font-size: 24px;
  line-height: normal;
}
h5 {
  font-size: 20px;
  line-height: normal;
}
h6 {
  font-size: 16px;
  line-height: normal;
}
select::-ms-expand {
  display: none;
}
select {
  -moz-appearance: none;
}
.alignleft {
  float: left;
  margin-right: 1.5em;
  margin-bottom: 15px;
  margin-top: 8px;
}
.alignright {
  float: right;
  margin-left: 1.5em;
  margin-bottom: 15px;
  margin-top: 8px;
}
.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 15px;
}
.default-css ul {
  margin-bottom: 20px;
}
.default-css ol {
  padding-left: 18px;
}
.default-css ul li,
.default-css ol li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #717b89;
  margin: 0 0 6px;
}
.default-css ul li a,
.default-css ol li a {
  color: #f26432;
}
.default-css ul li strong,
.default-css ol li strong {
  color: #f26432;
  font-weight: 600;
  font-family: "Poppins";
}
.default-css ul li a:hover,
.default-css ol li a:hover {
  color: #414114;
}
.default-css ul li {
  list-style: none !important;
  /* background: url(../images/list-arrow.png) no-repeat; */
  background-position: 0 4px;
  padding-left: 24px;
  background-size: 16px;
}

/*---HEADER CSS---*/
.header-section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  padding: 15px 0;
}
.header-section.sticky {
  position: fixed;
  background: #fff;
  box-shadow: 0px 0px 60px 0px rgba(15, 15, 13, 0.3);
}
.header-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
}
.header-menu .menu-navbar {
  display: inline-block;
}
.header-logo img {
  max-width: 245px;
  margin-left: -10px;
}
.header-section .row {
  align-items: center;
}
.header-section:before {
  content: "";
  position: absolute;
  /* background-image: url(../images/header-before.png); */
  height: 7px;
  left: 0;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/*---home-banner CSS---*/
section.home-banner {
  position: relative;
  padding: 140px 0 140px;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}

body:before {
  content: "";
  position: absolute;
  /* background: url(../images/bg-home.png); */
  background: url(../../../public/images/bg-home.png);
  top: 0%;
  right: 0px;
  background-size: 100% 100%;
  width: 1060px;
  height: 700px;
}

.hero-title small {
  display: block;
  font-size: 40px;
  font-weight: 400;
}

.header-btn a.theme-btn::before {
  background: #176b87;
}

.header-btn a.theme-btn:hover {
  color: #fff;
}

section.home-banner .row {
  align-items: center;
}

.header-section.sticky a.theme-btn {
  background: #cfe3ff;
}

.hero-title {
  color: #000;
  font-family: "Poppins";
  font-size: 50px;
  line-height: 70px;
  max-width: 780px;
  padding: 0 0 5px;
  font-weight: 600;
}
.hero-title strong {
  color: #176b87;
  font-weight: 600;
  font-style: italic;
}
a.theme-btn {
  display: inline-block;
  padding: 12px 26px;
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  font-family: "Poppins";
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  overflow: hidden;
  background: #36668c;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a.theme-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
a.theme-btn:hover:before {
  -webkit-transform: scale(2);
  transform: scale(2);
}
.hero-btn a.theme-btn:before {
  background: #333;
}
.banner-caption {
  position: relative;
  max-width: 430px;
}
.banner-caption p {
  font-size: 18px;
  line-height: 28px;
  max-width: 620px;
}
.hero-btn {
  padding: 20px 0 0;
}
.header-btn {
  padding-left: 60px;
}
.hero-title span {
  color: #333333;
}
.banner-img {
  position: relative;
}
.banner-img img {
  width: 100%;
}
.header-btn a.theme-btn {
  padding: 9px 23px;
  font-size: 18px;
  line-height: 20px;
  background: #fff;
  color: #36668c;
}

.business-caption {
  max-width: 545px;
}
section.business-section {
  position: relative;
}
section.business-section .row {
  align-items: center;
  position: relative;
}
section.business-section:before {
  content: "";
  position: absolute;
  /* background: url(../images/business-bg.png); */
  background: url(../../../public/images/business-bg.png);
  width: 674px;
  height: 450px;
  top: 0%;
  left: 0px;
  background-size: 100% 100%;
}

section.Management-section {
  position: relative;
  padding: 110px 0 90px;
}
section.Management-section .row {
  align-items: center;
  position: relative;
}
section.Management-section:before {
  content: "";
  position: absolute;
  /* background: url(../images/Management-bg.png); */
  background: url(../../../public/images/Management-bg.png);
  height: 211px;
  left: 0px;
  background-size: 100% 100%;
  right: -0;
  bottom: 0;
}

.list-icon li {
  list-style: none;
  padding: 0 0 20px;
  display: flex;
}

.list-icon li figure {
  width: 40px;
  height: 40px;
  box-shadow: 0px 0.5px 5px 0px #36668cb5;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  float: left;
  margin: 0px 0 0;
}

.list-icon li figcaption {
  width: calc(100% - 40px);
  float: right;
  padding-left: 15px;
  color: #717b89;
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
}

.Management-caption {
  max-width: 680px;
}

.Management-caption h4 {
  color: #36668c;
  font-size: 35px;
  font-weight: 600;
  margin: 0 0 20px;
}

section.RealTime-section {
  padding: 70px 0 100px;
  background: #fbfbfd;
}

section.RealTime-section .row {
  align-items: center;
}

footer.footer-section {
  background: #166782;
  padding: 180px 0 40px;
  position: relative;
}

footer.footer-section:before {
  content: "";
  position: absolute;
  /* background: url(../images/footer-before.png); */
  background: url(../../../public/images/footer-before.png);
  height: 121px;
  left: 0px;
  background-size: 100% 100%;
  right: 0;
  top: -40px;
}

.Copyrighted {
  border-top: 1px solid #fff;
  padding: 30px 0 0;
  margin: 30px 0 0;
}

footer.footer-section p {
  font-size: 20px;
  line-height: 34px;
  color: #fff;
}

.footer-info h5 {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}

.footer-info ul li {
  list-style: none;
  margin: 0;
  font-size: 20px;
  line-height: 34px;
  color: #fff;
  font-family: "Poppins";
}

.footer-info ul li a {
  color: #fff;
}

.footer-info ul li a:hover {
  color: #ccc;
}

.social-media {
  text-align: right;
}

.social-media li {
  list-style: none;
  display: inline;
  margin: 0 5px;
}

.social-media li a {
  background: #fff;
  width: 38px;
  height: 38px;
  line-height: 40px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #176b87;
  font-size: 20px;
}

.footer-info.menuf {
  padding-left: 50px;
}

.row.Singhtek-box {
  background: #fff;
  margin: 0;
  border-radius: 8px;
  padding: 40px 30px;
  /* border-r\: 8; */
  align-items: center;
  box-shadow: 0px 8px 9px -1px #00000040;
}

.row.Singhtek-box p {
  color: #524d4d;
  font-size: 18px;
  line-height: 30px;
  margin: 0;
}

.Singhtek-section {
  position: absolute;
  left: 0;
  right: 0;
  top: -60px;
}

section.contact-section {
  padding: 120px 0 0;
  position: relative;
}

.contact-caption {
  box-shadow: 0px 0px 35px 0px #36668c66;
  background: #fff;
  padding: 80px 50px;
  border-radius: 12px;
}

.contact-head h4 {
  color: #524d4d;
  font-size: 25px;
  font-weight: 500;
  margin: 0 0 5px;
}

.contact-head h5 {
  color: #978d8d;
  font-weight: 400;
  font-size: 22px;
}

.contact-head {
  padding: 0px 0 20px;
}

.form-group {
  margin: 0 0 25px;
}

.form-group input.form-control {
  box-shadow: 0px 0px 6px 0px #00000029;
  background: #fff;
  border: none;
  color: #717b89;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  padding: 8px 20px;
}

.check-box-div {
  padding: 10px 0 0;
}

.check-box-div h5 {
  color: #373d3f;
  font-weight: 400;
  font-size: 22px;
  margin: 0 0 10px;
}

.check-box-div p {
  font-size: 18px;
  line-height: 30px;
}

label.form-check-label {
  color: #978d8d;
}

.check-box-row {
  padding: 0 0 10px;
}

.check-box-row .form-check {
  display: inline-block;
  margin: 0 30px 10px 0px;
}

.form-btn {
  padding: 20px 0 0;
}

.form-btn button.btn {
  padding: 12px 33px;
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  font-family: "Poppins";
  display: inline-block;
  background: #36668c;
}

@media (min-width: 1200px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1270px !important;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1330px !important;
  }
}
@media (min-width: 1550px) {
  .container {
    max-width: 1370px !important;
  }
}
@media (min-width: 1700px) {
  .container {
    max-width: 1470px !important;
  }
}

@media (max-width: 1700px) {
}

@media (max-width: 1600px) {
}

@media (max-width: 1199px) {
}

@media (max-width: 812px) {
}

@media (max-width: 575px) {
}
