button.sub-menu-toggle {display: none;}
@media (min-width: 1200px) {
.navbar-collapse ul {margin: 0;padding: 0px;}
.navbar-collapse .menu-top-menu-container > ul > li {list-style: none;margin: 0px 12px;padding: 0;display: inline-block;position: relative;color: #101C2C;font-size: 20px;font-style: normal;font-weight: 500;line-height: 28px;font-family: 'Poppins';}
.navbar-collapse ul li a {
    vertical-align: middle;
    margin: 0 0px;
    display: block;
    color: #101C2C;
    padding: 0px;
}
.navbar-collapse .menu-top-menu-container>ul li:hover a, .navbar-collapse .menu-top-menu-container>ul li.current-menu-item a {color: #F26432;}
.navbar-collapse .menu-top-menu-container>ul li.current-menu-item a::before {opacity: 1;}
.navbar-collapse.collapse {display: block!important;}
.navbar-toggle {display: none;}
.navbar-collapse .menu-top-menu-container > ul > li a {position: relative;} 
.navbar-collapse ul .menu-item-has-children>a:after{
font-family: FontAwesome !important;
}
.menu-item-has-children > a:after {content: "\f107";font-family: FontAwesome !important;color: #ccc;font-size: 15px;margin-left: 3px;margin-top: 0px;transition: 1s;text-align: center;position: relative;top: 0px;line-height: 18px;}
.menu-item-has-children > ul > li.menu-item-has-children > a:after {content: "\f105";font-family: 'FontAwesome';margin-left: 0;font-size: 16px;float: right;margin-top: 0;width: auto;height: auto;background: none;color: #fff;position: absolute;right: 15px;top: 13px;bottom: auto;left: auto;}
.navbar-collapse > ul > li.menu-item-has-children:hover > a::after {content: "\f106";color: #F26432;}
.menu-item-has-children:hover > a:after {color: #F26432;}
.menu-item-has-children ul {position: absolute;width: 210px;list-style: none;text-align: left;z-index: 999;top: 110%;display: block !important;padding: 10px 0;left: 0;visibility: hidden;transform: scale(1, 0);transition: all 0.5s;transform-origin: top center;opacity: 0;box-shadow: 0px 14px 20px 0px rgba(15, 15, 15, 0.30);border-radius: 10px;background: #fff;}
.menu-item-has-children ul li {position: relative;width: 100%;float: left;padding: 0 !important;text-align: left;margin: 0px 0 0 !important;transition: 1s;border-right: none;border-radius: 0;background: #eceff4;list-style: none;}
.menu-item-has-children ul li:last-child {border-bottom: none;border-radius: 0 0 8px 8px;}
.menu-item-has-children ul li:last-child a {border-radius: 0 0 8px 8px;} 
.menu-item-has-children ul li.active >a {color: #fff !important;}
.menu-item-has-children ul li a {margin-left: 0;color: #101C2C !important;white-space: normal;width: 100%;float: none;text-decoration: none;text-align: left;padding: 9px 10px;display: block;-webkit-transition: all 0.3s ease-in 0s;transition: all 0.3s ease-in 0s;transition: all 0.3s ease-in 0s;font-size: 14px;background-color: #fff !important;font-family: 'Poppins';line-height: normal;}
.menu-item-has-children ul li a:before {border-left: none;border-right: none;display: none;}
.menu-item-has-children ul ul {position: absolute;list-style: none;text-align: left;padding-left: 0;z-index: 999;display: block;padding: 0px 0 0 0px;left: 100%;top: 0px !important;}
.menu-item-has-children ul ul li {position: relative;width: 100%;float: left;}
.menu-item-has-children ul ul li a:hover {color: #F26432 !important;position: relative;background-color: #fff;}
.menu-item-has-children ul li:hover ul {opacity: 1;visibility: visible;}
.menu-item-has-children:hover > ul {top: 100%;transform: scale(1);opacity: 1;visibility: visible;}
.menu-item-has-children ul li a:hover {background: #fff!important;color: #F26432 !important;padding-left: 15px;}
.menu-item-has-children ul li:hover > a::after {color: #F26432 !important;}
.menu-item-has-children>ul li:hover a::after, .navbar-collapse .menu-item-has-children>ul li.current-menu-item a::after {color: #F26432 !important;}
.menu-item-has-children ul li:hover>a {color: #F26432 !important;background-color: #fff !important;}
.navbar-collapse .menu-top-menu-container > ul > li:last-child {margin-right: 0;border: none;}
.header-menu .menu > .menu-item.menu-item-has-children > a::after {
	font-family: FontAwesome !important;
}

}
@media (max-width: 1899px) {
.menu-item-has-children ul li a {padding: 10px 15px 10px 15px;}
}

@media (max-width: 1699px) {

.menu-item-has-children ul li a {
    padding: 10px 15px 10px 15px;
}
}



@media (max-width: 1599px) {
.menu-item-has-children ul li a {padding: 10px 15px 10px 15px;}

}
@media (max-width: 1550px) {
    .navbar-collapse .menu-top-menu-container > ul > li {
        font-size: 15px;
        margin: 0px 8px;
    }
    

    .menu-item-has-children > a:after {
        font-size: 14px;
        margin-left: 3px;
        top: 1px;
        line-height: 18px;
    }




.menu-item-has-children ul li a {padding: 10px 15px 10px 15px;}


}
@media (max-width: 1399px) {

.menu-item-has-children ul li a {padding: 10px 15px 10px 15px;}
}


@media (max-width: 1199px) {
.mobile-logo {display: block !important;width: 166px;padding: 10px;margin-left: 20px;text-align: left;background: #fff;text-align: center;margin-bottom: 20px;}
.mobile-logo img {
    max-width: 120px;
}
.navbar-collapse .menu-top-menu-container > ul > li:first-child {margin-left: 0px;}
div#myNavbar.collapse.show {right: 0;}
button:focus {outline: 0px dotted;outline: 0px auto;}
.menu-item-has-children > a:after {font-size: 15px;display: none;}
.main_navigation .navbar-inverse {position: unset;}
.navbar-header {
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 999;
    display: block;
}
.navbar-toggle.collapsed .icon-bar {background-color: #F26432 !important;width: 30px;height: 3px;float: left;}
.navbar-toggle .icon-bar {background-color: #fff !important;width: 40px;height: 4px;}
.navbar-toggle .icon-bar+.icon-bar {margin-top: 6px;}
div#navbarOpened.openMenu {width: 100%;height: 100%;top: 0;left: 0;position: fixed;background: rgba(0, 0, 0, 0.47);z-index: 99;}
.navbar-toggle {height: 40px;cursor: pointer;width: 35px;background: none;right: 15px;float: right;padding: 0px 0px;margin: 0;z-index: 999999;position: fixed;top: 17px;transform: scale(0.7);-webkit-transform: scale(0.7);-moz-transform: scale(0.7);border: none;border-radius: 4px;display: block;border-color: transparent;}
.navbar-toggle.collapsed {position: relative;transform: scale(1);-webkit-transform: scale(1);-moz-transform: scale(1);height: 30px;width: 30px;}
div#myNavbar {position: fixed;width: 350px;height: 100vh !important;z-index: 99;bottom: 0;top: 0;right: -100%;left: inherit;padding: 20px 0 20px;display: block !important;background: #17aab0;background-size: cover;overflow-y: auto;transition: 0.5s all;}
div#myNavbar.navbar-collapse.show {right: 0;}
/* div#myNavbar::after {background: rgba(68, 68, 68, 0.9);top: 0;bottom: 0;width: 100%;height: 100%; position: fixed;}
 */.navbar-collapse ul {text-align: left;margin: 0;display: block;padding: 0;position: relative;z-index: 9;float: left;width: 100%;}
.navbar-collapse ul > li {display: block;float: left;width: 100%;margin-left: 0px !important;margin-top: 0px !important;padding: 0;border-right: none;margin: 0;}
.navbar-collapse ul >li + li {border-top: solid 1px #ffffff;}
.navbar-collapse ul > li > a {font-size: 16px;padding: 9px 20px;font-weight: 400;text-align: left;transform: skew(0deg);margin: 0px;color: #fff !important;display: block;        font-family: 'Poppins';}
.navbar-collapse ul > li > a:hover, .navbar-collapse ul > li.current-menu-item > a {color: #fff;background: #F26432;}
.navbar-collapse ul > li > a:focus {color: #fff;}
.navbar-toggle.collapsed .icon-bar:first-child {transform: rotate(0deg);margin-top: 0px;}
.navbar-toggle.collapsed .icon-bar:last-child {transform: rotate(0deg);margin-top: 6px;}
.navbar-toggle .icon-bar:first-child {transform: rotate(45deg);margin-top: 6px;float: left;}
.navbar-toggle .icon-bar:last-child {transform: rotate(-45deg);margin-top: -5px;float: left;}
.navbar-toggle .icon-bar:nth-child(2) {display: none;}
.navbar-toggle.collapsed .icon-bar:nth-child(2) {display: block;margin-top: 6px;width: 30px;}
.menu-item-has-children .subItem {width: 43px;position: absolute;margin-top: 0;right: 0;z-index: 999;text-align: center;cursor: pointer;height: 42px;}
.menu-item-has-children .subItem:before { content: '\f107'; font-family: 'FontAwesome'; font-size: 18px; float: left; width: 100%; color: #fff; position: relative; top: 50%; transform: translateY(-50%); }
.menu-item-has-children.parentMenuActive>.subItem:before {
    content: '\f106';
    color: #fff;
}
.navbar-collapse .menu-item-has-children ul li a {background: #1f797d;}
.navbar-collapse .menu-item-has-children ul li a:hover {background: #F26432;}
.navbar-collapse .menu-item-has-children ul li ul li a {background: #3b6291;color: #fff;}
.navbar-collapse .menu-item-has-children ul li ul li a:hover {background: #F26432;}
.menu-item-has-children {position: unset;}
.menu-item-has-children:hover > a:before {display: none;}
.menu-item-has-children ul li {display: block;float: left;width: 100%;padding: 0;border: none;}
.menu-item-has-children ul li + li {border-top: solid 1px #ffffff;}
.menu-item-has-children ul li.active >a {color: #fff !important;}
.menu-item-has-children ul li ul {background: #262829;left: 0;}
body.open-menu div#myNavbar {animation: slide-in-right 0.6s forwards;}

.menu-item-has-children ul li b {
    font-size: 16px;
    padding: 9px 20px;
    font-weight: 400;
    text-align: left;
    transform: skew(0deg);
    margin: 0px;
    color: #fff;
    display: block;
            font-family: 'Poppins';background: #2a415d;
}

@keyframes slide-in-right {0% {right:-100%;}
100% {right:0px;}
}
@keyframes slide-out-right {0% {right:0px;}
100% {right:-100%;}
}
.mega-menu {display: none;}
.mega-menu ul.sub-menu {
    width: 100%;
}

}
@media (max-width: 812px ) {
}
@media (max-width: 767px ) {div#myNavbar {width: 100%;}
}
@media (max-width: 575px ) {
.navbar-header {}
}
@media (max-width: 480px ) {.navbar-inverse .navbar-collapse.collapse {top: 53px;}
}




/*  */
/*  */
/* header-section lp-header */
.header-section.lp-header .navbar-collapse ul li a {
    margin: 0 10px;
    color: #fff;
}
.header-section.lp-header .navbar-collapse .menu-top-menu-container > ul > li {
    color: #fff;
            font-family: 'Poppins';
    font-size: 19px;
    text-transform: uppercase;
}
.header-section.lp-header .menu-item-has-children > a:after {
    color: #fff;
    font-size: 19px;
}
.header-section.lp-header .navbar-collapse .menu-top-menu-container>ul li:hover a, .header-section.lp-header .navbar-collapse .menu-top-menu-container>ul li.current-menu-item a {
    color: #fff;
    background-color: #F26432;
}
.header-section.lp-header .menu-item-has-children:hover > a:after {
    color: #fff;
}
.header-section.lp-header .menu-item-has-children ul li a{ font-family: 'Poppins';}

